//'use client'

import { Asterisk, ChevronRight, Clock } from 'lucide-react'
import banner from '../assets/images/banner-char.png'
import bgLines from '../assets/images/bg-lines.svg'
import barCode from '../assets/images/bar-code.svg'
import Countdown from 'react-countdown'
import ClockBox from './ClockBox'
import CheckItem from './CheckItem'
import Slider from './Slider'

export interface BannerProps {}

type Props = {
  days: number
  hours: number
  minutes: number
  seconds: number
  completed: boolean
}

const Completionist = () => (
  <span className="text-3xl">Aproveite agora esta oportunidade!</span>
)

const renderer = ({ days, hours, minutes, seconds, completed }: Props) => {
  if (completed) {
    // Render a completed state
    return <Completionist />
  } else {
    // Render a countdown
    return (
      <div className="flex w-full flex-1 gap-2 lg:gap-6">
        <ClockBox text="dias" n={days} />
        <ClockBox text="horas" n={hours} />
        <ClockBox text="minutos" n={minutes} />
        <ClockBox text="segundos" n={seconds} />
      </div>
    )
  }
}

export default function Banner(props: BannerProps) {
  return (
    <div
      className="relative flex w-full flex-col items-center px-4 py-8 md:px-10"
      style={{
        backgroundImage: `url(${bgLines})`,
        backgroundSize: 'contain',
        backgroundPosition: 'top center',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <div className="absolute inset-0 z-0 bg-black opacity-40"></div>
      <div className="relative z-10 flex w-full max-w-[1440px] flex-col justify-between gap-12">
        <div className="flex w-full flex-1 flex-col items-center justify-between gap-4 md:flex-row">
          <div className="relative z-10 flex flex-1 flex-col items-start gap-8">
            <p className="flex items-center gap-2">
              <Asterisk className="text-blue-500" size={24} /> Aproveite a
              oportunidade de ter o seu próprio servidor
            </p>
            <h1 className="max-w-[643px] text-5xl font-bold leading-[130%] text-white lg:text-7xl">
              Seja dono do melhor servidor de{' '}
              <span className="pixelify-sans-mu uppercase text-blue-500">
                Mu online
              </span>
            </h1>
            <p className="max-w-[420px] text-xl">
              Tenha um servidor exclusivo com sistemas avançados, sem
              mensalidades no primeiro ano e suporte completo.
            </p>
            <a
              href="#plans"
              className="flex items-center gap-2 rounded-md bg-gradient-to-r from-blue-700 via-blue-500 to-blue-700 px-8 py-6 font-bold uppercase transition-colors hover:bg-gradient-to-tl"
            >
              Garanta seu servidor agora <ChevronRight size={18} />
            </a>
          </div>
          {/* <img
            src={banner}
            alt="Mu online"
            className="absolute z-0 w-full max-w-[669px] opacity-40 lg:relative lg:opacity-100"
          /> */}
          <Slider />
        </div>
        <div className="relative z-10 mt-8 flex w-full flex-col items-center gap-8 lg:flex-row lg:items-end">
          <div className="flex w-full max-w-[380px] flex-1 flex-row items-center justify-center gap-4 lg:max-w-[161px] lg:flex-col lg:items-start lg:justify-between lg:gap-14">
            <div className="">
              <Clock size={32} className="text-blue-500" />
            </div>
            <p className="lg:text-md text-lg text-slate-400">
              Oferta válida por tempo limitado
            </p>
          </div>

          <div className="flex flex-1 flex-col items-center justify-between gap-8 lg:flex-row lg:items-end">
            <Countdown
              date={Date.now() + 60 * 60 * 24 * 11 * 1000}
              // intervalDelay={0}
              // precision={6}
              renderer={renderer}
            />
            <div className="flex">
              <img src={barCode} alt="Lines Code Mu" className="w-full" />
            </div>
          </div>
        </div>
        <div className="mt-16 flex w-full flex-1 flex-wrap justify-around gap-4">
          <CheckItem text="Domínio incluso" />
          <CheckItem text="Emulador próprio" />
          <CheckItem text="Sem custos adicionais" />
          <CheckItem text="Configuração rápida" />
        </div>
      </div>
    </div>
  )
}
