//'use client'

import { TrendingUp } from 'lucide-react'
import ebook from '../../assets/images/ebook.png'

export interface BonusProps {}

export default function Bonus(props: BonusProps) {
  return (
    <section className="relative mx-auto flex w-full max-w-[1440px] flex-col justify-between gap-16 px-2 lg:flex-row">
      <article className="relative z-10 grid gap-8">
        <p className="text-xs font-semibold uppercase text-blue-500">
          BÔNUS DO PLANO ULTRA
        </p>
        <h1 className="text-5xl font-bold">
          Domine a arte de <span className="text-blue-500">administrar</span>{' '}
          servidores
        </h1>
        <p className="max-w-[557px] text-lg">
          Receba este ebook e aprenda tudo o que precisa para personalizar e
          gerenciar um servidor de sucesso
        </p>

        <div className="mt-16 grid gap-8">
          <TrendingUp className="text-blue-500" size={32} />
          <h2 className="text-slate-50/50">
            Números que você pode ter com um servidor próprio
          </h2>
          <div className="flex flex-wrap gap-8 lg:gap-24">
            <div className="grid w-full gap-4 md:max-w-[174px]">
              <p className="text-3xl font-bold">10 mil</p>
              <p className="text-sm">
                jogadores ativos em servidores personalizados
              </p>
            </div>
            <div className="grid w-full gap-4 md:max-w-[174px]">
              <p className="text-3xl font-bold">Até 200%</p>
              <p className="text-sm">de receita com monetização flexíveis</p>
            </div>
            <div className="grid w-full gap-4 md:max-w-[174px]">
              <p className="text-3xl font-bold">até 50%</p>
              <p className="text-sm">Economia nos custos de operação</p>
            </div>
          </div>
        </div>
      </article>
      <article className="">
        <img
          src={ebook}
          alt="Ebook Mu Danki"
          className="relative z-10 max-w-[332px]"
        />
        <div className="absolute right-0 top-0 z-0 h-[700px] w-[800px] rounded-full bg-pink-500/15 blur-[100px]"></div>
      </article>
    </section>
  )
}
