//'use client'

import { Check, ChevronRight } from 'lucide-react'
import { ComponentProps } from 'react'

export interface CardProps extends ComponentProps<'article'> {
  title: string
  value: string
  installments: string
  items: string[]
  link: string
  theme: string
}

export default function Card({
  title,
  value,
  installments,
  items,
  link,
  className,
  theme,
  ...props
}: CardProps) {
  return (
    <article
      className={`flex w-full flex-1 flex-col justify-between gap-8 rounded-xl border px-6 py-8 backdrop-blur-sm md:max-w-[384px] ${className}`}
    >
      <div className="flex flex-col gap-8">
        <h1 className="text-sm font-semibold uppercase">{title}</h1>
        <div className="grid gap-2">
          <p className="text-4xl font-semibold">{value}</p>
          <p className="text-sm text-slate-300">ou {installments}</p>
        </div>
        <div className="border-b border-slate-500/50" />
        <div className="flex flex-col gap-2">
          {items.map((v, i) => {
            return (
              <p key={i} className="flex items-center gap-2">
                <Check size={16} className="text-blue-500/50" /> {v}
              </p>
            )
          })}
        </div>
      </div>

      <a
        href={link}
        className={`flex items-center justify-center gap-2 rounded-lg transition-colors ${theme} px-8 py-4 text-center font-semibold uppercase`}
      >
        Quero o {title} <ChevronRight size={16} />
      </a>
    </article>
  )
}
