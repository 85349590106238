//'use client'

import { ComponentProps } from 'react'

export interface CardProps extends ComponentProps<'img'> {
  title: string
  description: string
  src: string
}

export default function Card({ className, ...props }: CardProps) {
  return (
    <article className="bg-border overflow-hidden rounded-2xl bg-gradient-to-b from-slate-700 to-slate-950 p-[1px]">
      <div className="relative flex flex-col gap-5">
        <div className="lef-0 absolute bottom-0 z-20 flex flex-col gap-4 p-12">
          <p className="text-2xl font-bold">{props.title}</p>
          <p className="text-white/80">{props.description}</p>
        </div>
        <div className="absolute z-10 h-full w-full bg-gradient-to-b from-transparent to-slate-950/90 to-85%" />
        <img
          src={props.src}
          alt={props.title}
          className={`z-0 rounded-2xl ${className}`}
        />
      </div>
    </article>
  )
}
