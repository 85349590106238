//'use client'

export interface CardProps {
  title: string
  text: string
  img: string
}

export default function Card(props: CardProps) {
  return (
    <div className="relative flex w-full min-w-[288px] flex-1 justify-end overflow-hidden rounded-2xl border border-blue-800/50 bg-blue-900/50 p-8 pt-[340px]">
      <img
        src={props.img}
        alt={props.title}
        className="absolute -right-10 top-8 z-0 opacity-40"
      />
      <div className="absolute bottom-0 left-0 right-0 top-0 h-full w-full bg-gradient-to-b from-transparent to-black"></div>
      <div className="relative z-20 grid max-w-[288px] gap-6">
        <h1 className="text-2xl font-bold">{props.title}</h1>
        <p>{props.text}</p>
      </div>
    </div>
  )
}
