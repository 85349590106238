//'use client'

import {
  HOTMARLINK_STANDARD_INSTALLMENT,
  HOTMARLINK_STANDARD_PRICE
} from '../constants'

export interface StickyProps {}

export default function Sticky(props: StickyProps) {
  return (
    <div className="sticky top-0 z-50 mx-auto flex w-full justify-center border-b border-b-slate-800 bg-slate-900/80 backdrop-blur-sm transition-all">
      <div className="flex w-full max-w-[1440px] items-center justify-between gap-4 px-2 py-4 text-white md:py-6">
        <h1 className="text-center text-xs font-semibold uppercase text-yellow-400 md:text-lg">
          Promoção maluca para você ter o seu servidor hoje🔥
        </h1>
        <a
          href="#plans"
          className="lg:text-md flex items-center gap-2 text-nowrap rounded-md bg-gradient-to-r from-blue-700 via-blue-500 to-blue-700 px-4 py-3 text-xs font-bold uppercase transition-colors hover:bg-gradient-to-tl md:px-8"
        >
          Por até {HOTMARLINK_STANDARD_INSTALLMENT}
        </a>
      </div>
    </div>
  )
}
