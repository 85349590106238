//'use client'

export interface ClockBoxProps {
  n: number
  text: string
}

export default function ClockBox({ n, text }: ClockBoxProps) {
  return (
    <div className="flex h-[104px] w-[90px] flex-1 flex-col items-center justify-between gap-4 rounded-lg border-2 border-slate-900 bg-gradient-to-br from-blue-500/40 to-transparent p-4 lg:h-[144px] lg:w-[150px] lg:p-6">
      <span className="text-2xl lg:text-4xl">{n}</span>
      <span className="lg:text-md text-sm font-semibold text-slate-500">
        {text}
      </span>
    </div>
  )
}
