//'use client'

import { ChevronRight } from 'lucide-react'
import bgLastChance from '../../assets/images/bg-last-chance.png'

export interface LastChanceProps {}

export default function LastChance(props: LastChanceProps) {
  return (
    <div className="relative mx-auto my-20 w-full max-w-[1260px] px-2 pt-12 lg:px-0">
      <div className="relative z-10 overflow-hidden rounded-3xl border border-purple-900 bg-gradient-to-tr from-purple-900/50 to-purple-900 p-8 lg:p-20">
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-tr from-transparent via-slate-50 via-90% to-transparent opacity-30" />
        <div className="relative z-10 flex max-w-[560px] flex-col items-start gap-8">
          <p className="mb-6 text-3xl font-bold leading-[130%] text-white drop-shadow-xl lg:text-4xl">
            Chega de jogar em servidores de outras pessoas, agora você pode ter
            o <span className="text-purple-500">seu próprio servidor</span>
          </p>
          <a
            href="#plans"
            className="flex items-center gap-2 rounded-lg bg-white px-8 py-4 font-semibold uppercase text-purple-600 shadow-md transition-colors hover:bg-purple-950 hover:text-white"
          >
            <span>Comece agora</span> <ChevronRight size={16} />
          </a>
        </div>
      </div>
      <img
        src={bgLastChance}
        alt="Mu Danki"
        className="absolute bottom-0 right-0 z-0 w-full max-w-[700px] rounded-r-3xl opacity-90 lg:z-10 lg:opacity-100"
      />
    </div>
  )
}
