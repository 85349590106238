//'use client'

import { NEXT_PUBLIC_CDN_IMAGE } from '../../constants'

export interface CharsProps {}

export default function Chars(props: CharsProps) {
  return (
    <div className="mx-auto mt-36 flex max-w-[1260px] flex-wrap items-start justify-center gap-6 px-6">
      <div className="bg-purple-650 min-w-[220px] flex-1 overflow-hidden rounded-3xl bg-slate-800/50">
        <img
          className="w-full"
          src={`${NEXT_PUBLIC_CDN_IMAGE}/mu-danki/char-1.png`}
          alt="Mu Danki Chars"
        />
      </div>
      <div className="bg-purple-650 mt-0 min-w-[220px] flex-1 overflow-hidden rounded-3xl bg-slate-800/50 lg:mt-12">
        <img
          className="w-full"
          src={`${NEXT_PUBLIC_CDN_IMAGE}/mu-danki/char-2.png`}
          alt="Mu Danki Chars"
        />
      </div>
      <div className="bg-purple-650 min-w-[220px] flex-1 overflow-hidden rounded-3xl bg-slate-800/50">
        <img
          className="w-full"
          src={`${NEXT_PUBLIC_CDN_IMAGE}/mu-danki/char-3.png`}
          alt="Mu Danki Chars"
        />
      </div>
      <div className="bg-purple-650 mt-0 min-w-[220px] flex-1 overflow-hidden rounded-3xl bg-slate-800/50 lg:mt-12">
        <img
          className="w-full"
          src={`${NEXT_PUBLIC_CDN_IMAGE}/mu-danki/char-4.png`}
          alt="Mu Danki Chars"
        />
      </div>
      <div className="bg-purple-650 min-w-[220px] flex-1 overflow-hidden rounded-3xl bg-slate-800/50">
        <img
          className="w-full"
          src={`${NEXT_PUBLIC_CDN_IMAGE}/mu-danki/char-5.png`}
          alt="Mu Danki Chars"
        />
      </div>
    </div>
  )
}
