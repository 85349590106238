//'use client'

import guide1 from '../../assets/images/guide-mu-1.png'
import guide2 from '../../assets/images/guide-mu-2.png'
import guide3 from '../../assets/images/guide-mu-3.png'
import guide4 from '../../assets/images/guide-mu-4.png'

import Card from './Card'

export interface GuideProps {}

export default function Guide(props: GuideProps) {
  return (
    <section
      id="benefits"
      className="mx-auto grid w-full max-w-[1260px] place-items-center gap-20 px-4"
    >
      <div className="flex flex-col items-center justify-center gap-8">
        <p className="text-center text-xs font-bold uppercase text-blue-400">
          Benefícios exclusivos
        </p>
        <h1 className="text-center text-3xl font-bold md:text-4xl">
          O que você <span className="text-blue-500">ganha</span> ao escolher
          nosso serviço?
        </h1>
        <p className="text-center text-lg">
          Desenvolvemos o projeto mais completo para ajudar você a criar e
          gerenciar um servidor lucrativo e de sucesso.
        </p>
      </div>

      <div className="flex flex-1 flex-wrap items-center justify-center gap-8">
        <Card
          title="Escalabilidade"
          description="Configure seu servidor para crescer"
          src={guide1}
          className="w-full max-w-[384px]"
        />
        <Card
          title="Pagamento fácil"
          description="Plataforma DankiPay para fazer seus saques rápidos e seguros"
          src={guide4}
          className="w-full max-w-[800px]"
        />
        <Card
          title="Backup automático"
          description="Sistema de backup para garantir a segurança dos seus dados"
          src={guide2}
          className="w-full max-w-[596px]"
        />
        <Card
          title="Garantia"
          description="Garantia de 7 dias para reembolso total"
          src={guide3}
          className="w-full max-w-[596px]"
        />
      </div>
    </section>
  )
}
