//'use client'

export interface CardSiteProps {
  title: string
  text: string
  img: string
  link?: string
}

export default function CardSite(props: CardSiteProps) {
  return (
    <div className="relative flex w-full min-w-[288px] flex-1 justify-end overflow-hidden rounded-2xl border border-blue-800/50 bg-blue-900/50 p-8 pt-[340px]">
      <img
        src={props.img}
        alt={props.title}
        className="absolute -left-10 top-8 z-0 opacity-80"
      />
      <div className="absolute bottom-0 left-0 right-0 top-0 h-full w-full bg-gradient-to-b from-transparent to-black"></div>
      <div className="relative z-20 grid gap-6">
        <h1 className="text-2xl font-bold">{props.title}</h1>
        <p>{props.text}</p>
        {props.link &&
          <p>
            Clique para ver a{' '}
            <a
              href={props.link}
              target="_blank"
              className="font-bold text-blue-500 hover:text-blue-300"
            >
              Demo
            </a>
          </p>
        }
      </div>
    </div>
  )
}
