//'use client'
import bgInGame from '../assets/images/bg-in-game.png'

export interface BoxInGameProps {}

export default function BoxInGame(props: BoxInGameProps) {
  return (
    <div className="relative flex flex-1 justify-center">
      <div className="absolute inset-0 z-0 mx-auto max-h-[720px] max-w-[1100px] rounded-full bg-blue-900 opacity-60 blur-3xl"></div>
      <div className="relative z-10 mx-auto my-32 flex aspect-video w-full max-w-[1171px] flex-1 flex-col overflow-hidden rounded-3xl border border-slate-800 bg-gradient-to-br from-blue-950/50 to-slate-800/50 p-2 lg:rounded-[42px] lg:p-6">
        <div className="absolute inset-0 z-10 bg-gradient-to-b from-transparent from-40% to-slate-950"></div>
        <div
          style={{
            backgroundImage: `url("${bgInGame}")`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat'
          }}
          className="relative z-0 aspect-video rounded-2xl"
        ></div>
      </div>
    </div>
  )
}
