import Banner from './components/Banner'
import Bonus from './components/Bonus'
import BoxInGame from './components/BoxInGame'
import BoxVideo from './components/BoxVideo'
import Chars from './components/Chars'
import Depoiments from './components/Depoiments'
import Engine from './components/Engine'
import Explore from './components/Explore'
import Faq from './components/Faq'
import Footer from './components/Footer'
import Guide from './components/Guide'
import Header from './components/Header'
import LastChance from './components/LastChance'
import Line from './components/Line'
import Plan from './components/Plan'
import Sticky from './components/Sticky'
import Youtube from './components/Youtube'

function App() {
  return (
    <main className="relative w-full bg-black">
      <Sticky />
      <div className="relative flex min-h-screen flex-1 flex-col gap-6 overflow-hidden bg-black text-white">
        <Header />
        <BoxVideo />
        <Banner />
        <BoxInGame />
        <Guide />
        <Line />
        {/* <Depoiments /> */}
        <LastChance />
        <Plan />
        <Line />
        <Bonus />
        <Chars />
        <Engine />
        <Line />
        <Youtube />
        <Faq />
        <Footer />
      </div>
    </main>
  )
}

export default App
