//'use client'

import { NEXT_PUBLIC_CDN_IMAGE } from '../../constants'
import YoutubeIcon from './YoutubeIcon'

export interface YoutubeProps {}

export default function Youtube(props: YoutubeProps) {
  return (
    <div className="my-18 mx-auto grid max-w-[1260px] place-items-center gap-20 px-6">
      <h1 className="flex items-center justify-center gap-6 text-center text-4xl font-bold lg:text-5xl">
        <span>
          <YoutubeIcon width={60} />
        </span>{' '}
        <span>Acompanhe no YOUTUBE</span>
      </h1>

      <div className="flex flex-col flex-wrap items-center gap-4 md:flex-row">
        <a
          href="https://www.youtube.com/watch?v=jpuUED31830"
          target="_blank"
          className="flex flex-1"
        >
          <img
            src={`${NEXT_PUBLIC_CDN_IMAGE}/mu-danki/yt-1.png`}
            alt="Mu Danki youtube"
          />
        </a>
        <a
          href="https://www.youtube.com/watch?v=DgfJtrL_FN4"
          target="_blank"
          className="flex flex-1"
        >
          <img
            src={`${NEXT_PUBLIC_CDN_IMAGE}/mu-danki/yt-2.png`}
            alt="Mu Danki youtube"
          />
        </a>
        <a
          href="https://www.youtube.com/watch?v=dsD2ZbGMvEc"
          target="_blank"
          className="flex flex-1"
        >
          <img
            src={`${NEXT_PUBLIC_CDN_IMAGE}/mu-danki/yt-3.png`}
            alt="Mu Danki youtube"
          />
        </a>
      </div>
    </div>
  )
}
