import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Autoplay } from 'swiper/modules'

import slider1 from '../../assets/images/slider-1.jpeg'
import slider2 from '../../assets/images/slider-2.jpeg'
import slider3 from '../../assets/images/slider-3.jpeg'
import slider4 from '../../assets/images/slider-4.jpg'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

const Slider = () => {
  const images = [slider1, slider1, slider3, slider4]

  return (
    <div className="relative mx-auto w-full max-w-3xl">
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={10}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false
        }}
        loop={true}
        className="w-full"
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <img
              src={image}
              alt={`Slide ${index + 1}`}
              className="h-[350px] w-full rounded-xl object-cover lg:h-[400px]"
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default Slider
