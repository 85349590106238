//'use client'
import bgInGame from '../assets/images/bg-in-game.png'

export interface BoxVideoProps {}

export default function BoxVideo(props: BoxVideoProps) {
  return (
    <div className="relative flex flex-1 flex-col items-center justify-center gap-6">
      <h1 className="relative z-20 px-2 text-center text-2xl font-semibold lg:text-3xl">
        Realize seu sonho e tenha seu servidor de Mu Online ainda hoje <br />[{' '}
        <span className="font-bold text-blue-500">+</span> Sem mensalidade no
        primeiro ano de <span className="text-blue-500 underline">VPS</span> e{' '}
        <span className="text-blue-500 underline">Hospedagem</span>]
      </h1>
      <div className="absolute inset-0 z-0 mx-auto max-h-[720px] max-w-[1220px] rounded-full bg-blue-900 opacity-60 blur-3xl"></div>
      <div className="relative z-10 mx-auto flex aspect-video w-full max-w-[1171px] flex-1 flex-col overflow-hidden border border-slate-800 bg-gradient-to-br from-blue-950/50 to-slate-800/50 p-2 lg:my-12 lg:rounded-3xl lg:rounded-[42px] lg:p-6">
        <div className="absolute inset-0 z-10 bg-gradient-to-b from-transparent from-40% to-slate-950"></div>
        <iframe
          src="https://www.youtube.com/embed/V1oZKV1CBjs?si=xw_6rye_bXav97Qv"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          // referrerpolicy="strict-origin-when-cross-origin"
          // allowfullscreen
          className="relative z-0 h-full w-full lg:rounded-2xl"
        />
      </div>
    </div>
  )
}
