//'use client'

import { ComponentProps } from 'react'

export interface YoutubeIconProps extends ComponentProps<'svg'> {}

export default function YoutubeIcon(props: YoutubeIconProps) {
  return (
    <svg
      width="69"
      height="48"
      viewBox="0 0 69 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M67.3448 7.49578C66.5545 4.54375 64.2346 2.2239 61.2826 1.43368C55.9373 4.29153e-07 34.4943 0 34.4943 0C34.4943 0 13.0512 4.29153e-07 7.70593 1.43368C4.75957 2.2239 2.43407 4.54375 1.64385 7.49578C0.21582 12.841 0.21582 24 0.21582 24C0.21582 24 0.215817 35.159 1.64949 40.5043C2.43971 43.4563 4.75957 45.7762 7.7116 46.5662C13.0568 48 34.4998 48 34.4998 48C34.4998 48 55.9431 48 61.2881 46.5662C64.2404 45.7762 66.5602 43.4563 67.3503 40.5043C68.7841 35.159 68.7841 24 68.7841 24C68.7841 24 68.7783 12.841 67.3448 7.49578Z"
        fill="#FF0000"
      />
      <path
        d="M27.6421 34.2851L45.4501 24.0011L27.6421 13.7169V34.2851Z"
        fill="white"
      />
    </svg>
  )
}
