//'use client'

import { Check } from 'lucide-react'

export interface CheckItemProps {
  text: string
}

export default function CheckItem({ text }: CheckItemProps) {
  return (
    <div className="flex items-center gap-4">
      <Check className="text-blue-500" size={18} />
      <p className="text-nowrap text-xl">{text}</p>
    </div>
  )
}
