export interface FaqProps {
  title: string
  content: string
  items?: string[]
}

export const Faq: FaqProps[] = [
  {
    title: 'Como vou receber o meu servidor?',
    content:
      'Após a sua compra, você receberá por email um formulário com todas as informações que precisamos para criar o seu servidor, após o envio do forumlário, de 1 à 3 dias você receberá por e-mail todos os dados para acessar seu servidor, controlar, monitorar. Assim como o painel do seu website.'
  },
  {
    title: 'Como posso atrair mais jogadores para o meu servidor?',
    content:
      'Algumas formas são: destaque o Servidor (conteúdo exclusivo e eventos), parcerias (colabore com influenciadores e streamers).'
  },
  {
    title: 'Posso personalizar totalmente o meu servidor de Mu Online?',
    content:
      'Sim! Você pode configurar a vontade, mas com nosso suporte podemos implementar configurações a mais.'
  },
  {
    title: 'Qual o tempo de suporte que vou ter para o meu servidor',
    content: 'O suporte é vitalício.'
  },
  {
    title: 'Quais versões eu posso escolher?',
    content:
      'Trabalhamos apenas com season 6 pois temos uma source própria e conseguimos desenvolver sistemas customizados caso você queira.'
  },
  {
    title: 'Quanto custa a mensalidade do dedicado?',
    content: 'Nada no primeiro ano, no segundo ano R$ 130,00 por mês.'
  },
  {
    title: 'Vou ter alguma forma mais simples de contatar vocês?',
    content:
      'Via WhatsApp após venda ter sido efetivada você receberá um número por e-mail junto ao formulário com as informações de como você quer o mesmo'
  },
  {
    title: 'Existe a chance da webzen processar o servidor?',
    content: 'Não'
  }
]
