//'use client'
import lines from '../assets/images/lines.png'

export interface LineProps {}

export default function Line(props: LineProps) {
  return (
    <div className="mx-auto my-24 max-w-[1260px]">
      <img src={lines} alt="Mu Danki" />
    </div>
  )
}
