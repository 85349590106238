import { useState } from 'react'
import { ChevronRight, Menu, X } from 'lucide-react'
import logo from '../assets/images/logo-mu.png'

export interface HeaderProps {}

export default function Header(props: HeaderProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const menuItems = [
    { href: '#benefits', label: 'Benefícios' },
    // { href: '#testimonials', label: 'Depoimentos' },
    { href: '#plans', label: 'Planos' },
    { href: '#faq', label: 'FAQ' }
  ]

  return (
    <div className="mx-auto flex w-full max-w-[1440px] justify-center bg-black px-4 py-8 md:px-8">
      <header className="flex w-full items-center justify-between gap-4">
        <img src={logo} alt="Servidores Mu Danki" className="max-w-[69px]" />

        {/* Menu Desktop */}
        <nav className="hidden items-center gap-12 font-semibold text-white md:flex">
          {menuItems.map((item) => (
            <a
              key={item.href}
              href={item.href}
              className="text-white hover:text-blue-500 hover:underline"
            >
              {item.label}
            </a>
          ))}
          <a
            href="#plans"
            className="flex items-center gap-2 text-blue-500 hover:underline"
          >
            Quero meu servidor <ChevronRight size={16} />
          </a>
        </nav>

        {/* Mobile Menu Toggle */}
        <button className="text-white md:hidden" onClick={toggleMenu}>
          {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
        </button>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="fixed inset-0 z-50 flex flex-col bg-black md:hidden">
            <div className="flex items-center justify-between p-4">
              <img
                src={logo}
                alt="Servidores Mu Danki"
                className="max-w-[69px]"
              />
              <button className="text-white" onClick={toggleMenu}>
                <X size={24} />
              </button>
            </div>

            <nav className="flex flex-1 flex-col items-center justify-center space-y-6 text-white">
              {menuItems.map((item) => (
                <a
                  key={item.href}
                  href={item.href}
                  className="text-2xl hover:text-blue-500 hover:underline"
                  onClick={toggleMenu}
                >
                  {item.label}
                </a>
              ))}
              <a
                href="#plans"
                className="flex items-center gap-2 text-2xl text-blue-500 hover:underline"
                onClick={toggleMenu}
              >
                Quero meu servidor <ChevronRight size={16} />
              </a>
            </nav>
          </div>
        )}
      </header>
    </div>
  )
}
