//'use client'

export interface FooterProps {}

export default function Footer(props: FooterProps) {
  return (
    <div className="flex w-full justify-center bg-zinc-900 px-6 py-8">
      <div className="flex w-full max-w-[1440px] flex-col items-center justify-between gap-6 sm:flex-row">
        <p className="text-sm text-white/60">
          © 2024 - Todos os direitos reservados
        </p>
        <p className="text-sm text-white/60">Mu Danki</p>
      </div>
    </div>
  )
}
