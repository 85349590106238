export const HOTMARLINK_STANDARD_LINK =
  'https://pay.hotmart.com/D96831005M?off=chxwnwht&checkoutMode=10'
export const HOTMARLINK_STANDARD_PRICE = 'R$ 597,00'
export const HOTMARLINK_STANDARD_INSTALLMENT = '12x de R$ 58,16'

export const HOTMARLINK_ULTIMATE_LINK =
  'https://pay.hotmart.com/D96831005M?off=4lyn2uxg&checkoutMode=10'
export const HOTMARLINK_ULTIMATE_PRICE = 'R$ 697,00'
export const HOTMARLINK_ULTIMATE_INSTALLMENT = '12X DE R$ 67,91'

export const NEXT_PUBLIC_CDN_IMAGE = 'https://d1i4tvf70h7zdy.cloudfront.net'
