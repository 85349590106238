'use client'

import Accordion from './Accordion'
import DiscordIcon from './DiscordIcon'

export interface FaqProps {}

export default function Faq(props: FaqProps) {
  return (
    <div id="faq" className="mx-auto my-28 grid w-full max-w-[1260px] px-6">
      <div className="flex w-full flex-col justify-between gap-12 md:flex-row lg:gap-32">
        <div className="flex w-full flex-col gap-10 md:max-w-[280px]">
          <DiscordIcon />
          <h1 className="text-4xl font-bold">Ainda tem dúvidas?</h1>
          <p className="text-xl text-white/80">
            Confira as perguntas mais recorrentes que deixam no nosso discord.
          </p>
        </div>

        <Accordion />
      </div>
    </div>
  )
}
