'use client'

import { Fragment, useState } from 'react'
import { Faq } from './faq'

export default function Accordion() {
  const [activeIndex, setActiveIndex] = useState<number | null>(0)

  const onHeaderClick = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index)
  }

  const renderedItems = Faq.map((item, index) => {
    const isActive = index === activeIndex ? 'active' : 'inactive'

    return (
      <Fragment key={index}>
        <div
          className={`accordion-header text-lg ${isActive}`}
          onClick={() => onHeaderClick(index)}
        >
          {item.title}
          <span className="text-4xl leading-3 text-blue-500">
            {index === activeIndex ? <h1>-</h1> : <h1>+</h1>}
          </span>
        </div>
        <div className={`accordion-content ${isActive}`}>
          <div className="px-4 pl-4 pt-4">
            <p dangerouslySetInnerHTML={{ __html: item.content }} />
            {item?.items?.map((p, index) => <p key={index}>{p}</p>)}
          </div>
        </div>
      </Fragment>
    )
  })

  return <div className="accordion">{renderedItems}</div>
}
