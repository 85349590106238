//'use client'

import Card from './Card'
import pay from '../../assets/images/auto-pay.png'
import control from '../../assets/images/panel-control.png'
import rank from '../../assets/images/ranking.png'
import { ChevronRight } from 'lucide-react'
import CardSite from './CardSite'
import musite1 from '../../assets/images/mudanki-s6.png'
import musite2 from '../../assets/images/mudaredevil.png'
import musite3 from '../../assets/images/mudanki-s6-new.png'

export interface EngineProps { }

export default function Engine(props: EngineProps) {
  return (
    <section className="mx-auto my-28 flex w-full flex-col items-center justify-center gap-36">
      <div className="flex flex-col items-center gap-12">
        <h1 className="max-w-[784px] px-2 text-center text-2xl font-bold lg:text-5xl">
          Nesta oferta vamos disponibilizar o{' '}
          <span className="text-blue-500">MuDankiEngine</span> para você
        </h1>
        <div className="flex w-full flex-1 flex-wrap gap-8 px-2">
          <Card
            title="Pagamento automático"
            img={pay}
            text="Transações rápidas e seguras integradas ao servidor."
          />
          <Card
            title="Painel de controle"
            img={control}
            text="Gerencie tudo em um lugar, de forma simples."
          />
          <Card
            title="Sistema de rankings"
            img={rank}
            text="Destaque os melhores jogadores em tempo real."
          />
        </div>
      </div>

      <article className="flex w-full max-w-7xl flex-col items-center gap-12 px-2">
        <h1 className="max-w-[560px] px-2 text-center text-2xl font-bold lg:text-3xl">
          Confira alguns dos modelos de{' '}
          <span className="text-blue-500">site</span> que você pode escolher
        </h1>
        <div className="flex w-full flex-1 flex-wrap gap-8 px-2">
          <CardSite
            title="MuDankiS6"
            img={musite1}
            text="Tema vermelho, completo e com sistemas de Ranking, Notícias, Players online e muito mais"
          />
          <CardSite
            title="MuDankiS6"
            img={musite3}
            text="Tema marrom, completo e com sistemas de Ranking, Notícias, Players online e muito mais"
            link="https://mu.dankicode.com/"
          />
          <CardSite
            title="MuDareDevil"
            img={musite2}
            text="Tema roxo, completo e com sistemas de Ranking, Notícias, Players online e muito mais"
            link="https://mudaredevil.net/"
          />
        </div>
      </article>

      <div className="flex flex-col items-center justify-center gap-6">
        <h2 className="text-center text-3xl font-bold lg:text-5xl">
          Seu servidor está a <span className="text-blue-500">um clique</span>{' '}
          de distância
        </h2>
        <p className="text-center text-lg">
          Não perca a chance de criar o servidor dos seus sonhos. Escolha um
          plano e comece sua jornada hoje mesmo
        </p>
        <a
          href="#plans"
          className="mt-12 flex items-center gap-2 rounded-md bg-gradient-to-r from-blue-700 via-blue-500 to-blue-700 px-8 py-6 font-bold uppercase transition-colors hover:bg-gradient-to-tl"
        >
          Quero meu servidor agora <ChevronRight size={18} />
        </a>
      </div>
    </section>
  )
}
