//'use client'

import bgPlan from '../../assets/images/bg-plan-char.png'

import {
  HOTMARLINK_STANDARD_INSTALLMENT,
  HOTMARLINK_STANDARD_LINK,
  HOTMARLINK_STANDARD_PRICE,
  HOTMARLINK_ULTIMATE_INSTALLMENT,
  HOTMARLINK_ULTIMATE_LINK,
  HOTMARLINK_ULTIMATE_PRICE,
  NEXT_PUBLIC_CDN_IMAGE
} from '../../constants'
import Card from './Card'

export interface PlanProps {}

const Plans = [
  'Mu server completo Season 6',
  'Servidor',
  'Site',
  'Client',
  'Suporte',
  'Garantia de 7 dias',
  'Launcher com auto update',
  'Anti hack'
]

export default function Plan(props: PlanProps) {
  return (
    <section
      className="relative my-28 flex w-full flex-col items-center pb-[480px]"
      style={{
        background: `url("${NEXT_PUBLIC_CDN_IMAGE}/1200x1024/mu-danki/bg-topo.jpg")`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'top center'
      }}
    >
      <div className="absolute z-0 flex h-full w-full flex-1 bg-gradient-to-b from-black via-transparent via-50% to-black" />
      <div
        id="plans"
        className="relative z-10 mx-auto flex w-full max-w-[1440px] flex-col gap-20 px-2"
      >
        <div className="flex flex-col items-center justify-center gap-8">
          <h1 className="text-center text-3xl font-bold md:text-4xl">
            Escolha seu <span className="text-blue-500">plano</span> ideal
          </h1>
          <p className="text-center text-lg">
            Temos a solução perfeita para você começar hoje mesmo.
          </p>
        </div>

        <div className="flex flex-wrap justify-center gap-8">
          <Card
            title="Standard"
            value={HOTMARLINK_STANDARD_PRICE}
            installments={HOTMARLINK_STANDARD_INSTALLMENT}
            items={[...Plans]}
            link={HOTMARLINK_STANDARD_LINK}
            className="border-slate-700/50 bg-slate-900/50"
            theme="bg-white text-blue-500 hover:bg-blue-500 hover:text-white"
          />
          <Card
            title="Ultra"
            value={HOTMARLINK_ULTIMATE_PRICE}
            installments={HOTMARLINK_ULTIMATE_INSTALLMENT}
            items={[
              ...Plans,
              'Download código fonte source',
              'Curso: Manipular Source',
              'Suporte prioritário',
              'Divulgação no canal Danki Code',
              'Divulgação no Discord',
              'Ebook: 10 Estratégias para Bombar seu servidor'
            ]}
            link={HOTMARLINK_ULTIMATE_LINK}
            className="border-blue-800/50 bg-blue-900/50"
            theme="bg-blue-500 text-white hover:bg-white hover:text-blue-500"
          />
        </div>
      </div>
      <div className="absolute bottom-0 flex max-w-[1000px] justify-center">
        <div className="absolute -bottom-60 z-0 h-[600px] w-[900px] rounded-full bg-blue-500/15 blur-[100px]"></div>
        <img src={bgPlan} alt="Planos Mu danki" />
      </div>
    </section>
  )
}
